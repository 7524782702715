.diff-viewer {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 0.75rem;
}
  
.diff-line {
    font-family: inherit;
}
  
.diff-line-add {
    background-color: rgba(25, 135, 84, 0.1);
}
  
.diff-line-delete {
    background-color: rgba(220, 53, 69, 0.1);
}
  
.diff-add {
    color: #198754;
}
  
.diff-delete {
  color: #dc3545;
}

/* Readable Root Cause */
.insight-container {
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .insight-container:hover {
    transform: translateX(2px);
    box-shadow: 0 2px 5px rgba(0,0,0,0.08);
  }
  
  .insight-text {
    color: #2c3e50;
    font-size: 0.95rem;
    line-height: 1.5;
    flex: 1;
  }
  
  .insight-references {
    display: flex;
    /* gap: 2px; */
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  
  .readable-root-cause .badge {
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 0.85rem;
    text-decoration: none;
    font-weight: normal;
  }
  
  .insight-accent-line {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background-color: #5935c9;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    opacity: 0.7;
  }
  
  @media (max-width: 576px) {
    .readable-root-cause .d-flex {
      flex-direction: column;
    }
    
    .readable-root-cause .references {
      margin-top: 8px;
      justify-content: flex-start;
    }
  }

  /* Github Commit Info */
  .github-commit-info .card-header {
    border-bottom: 1px solid rgba(0,0,0,0.1);
  }
  
  .github-commit-info .badge:hover {
    text-decoration: none;
  }

  /* Similar Issues */
  .similar-issues-list .issue-item:last-child {
    border-bottom: none !important;
  }
  
  .similar-issues-list .issue-item:hover {
    background-color: rgba(89, 53, 201, 0.03);
  }
  
  .similar-issues-list .issue-summary:hover {
    text-decoration: none;
  }

.font-size-085 {
  font-size: 0.85rem;
}