.step-number {
  font-weight: bold;
  color: #4a4a4a;
  background-color: #f5f3f3;
  padding: 2px 8px;
  border-radius: 12px;
  margin-right: 6px;
  min-width: 70px;
}

.highlight-logs {
  background-color: #f5f3f3;
  padding: 2px 6px;
  border-radius: 3px;
  font-weight: bold;
  color: #333;
  margin: 0 4px;
}

.service-name {
  background-color: #f5f3f3;
  padding: 2px 6px;
  border-radius: 3px;
  font-family: monospace;
  color: #5935c9;
  /* border: 1px solid #bdbdbd; */
  margin-left: 4px;
  margin-right: 8px;
  display: inline-block;
}

.custom-btn {
  background-color: #5935c9 !important;
  border-color: #5935c9 !important;
  color: white !important;
  transition: background-color 0.3s, color 0.3s !important;
}

.custom-btn:hover:not(:disabled) {
  background-color: white !important;
  color: #5935c9 !important;
  border-color: #5935c9 !important;
}

.custom-btn:disabled {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
  color: white !important;
  opacity: 0.65;
}

.outline-custom-btn {
  color: #5935c9 !important;
  border-color: #5935c9 !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s !important;
}

.outline-custom-btn:hover {
  color: white !important;
  background-color: #5935c9 !important;
}

.card-header-custom {
  background-color: #f8f9fa;
  /* color: #5935c9; */
}

/* Navbar styles */
.navbar {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-brand img {
  max-height: 40px;
}

.nav-link {
  color: #000 !important;
}

.nav-link:hover {
  color: #666 !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.dropdown-menu {
  right: 0;
  left: auto;
}

.label-text {
  font-size: 0.9em;
  font-family: serif;
}