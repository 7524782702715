.profile-container {
    display: flex;
    height: 100vh;
    color: #fff;
    background-color: #1e1e1e;
  }
  
  .sidebar {
    width: 200px;
    padding: 20px;
    background-color: #2c2c2c;
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar li {
    padding: 10px 0;
    cursor: pointer;
  }
  
  .sidebar li.active {
    font-weight: bold;
  }
  
  .main-content {
    flex-grow: 1;
    padding: 20px;
  }
  
  .tabs {
    margin-bottom: 20px;
  }
  
  .tabs span {
    margin-right: 20px;
    cursor: pointer;
  }
  
  .tabs span.active {
    font-weight: bold;
  }
  
  .form-group {
    margin-bottom: 20px;
  }