.service-item {
  background-color: white;
  transition: all 0.2s ease-in-out;
}

.service-item:hover {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1) !important;
}

.logs-preview {
  max-height: 150px;
  overflow-y: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0;
  border: 1px solid #dee2e6;
}

.metrics-details summary {
  cursor: pointer;
  user-select: none;
}

.metrics-details summary::-webkit-details-marker {
  display: none;
}

.metrics-list {
  max-height: 150px;
  overflow-y: auto;
}

.badge {
  font-weight: normal;
  padding: 0.5em 0.8em;
} 